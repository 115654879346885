@import '~antd/dist/antd.less';

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
@primary-color: #467a3c;@layout-body-background: white;@layout-header-background: white;@font-family: MyriadPro;@table-row-hover-bg: #F8FBF1;@menu-item-height: 51px;@menu-inline-toplevel-item-height: 51px;@menu-item-active-bg: #008200;@layout-trigger-background: #205527;@layout-trigger-height: 43px;