@font-face {
  font-family: 'MyriadPro-BoldIt';
  src: url('Assets/fonts/MyriadPro-BoldIt.woff2') format('woff2'),
    url('Assets/fonts/MyriadPro-BoldIt.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'MyriadPro-CondIt';
  src: url('Assets/fonts/MyriadPro-CondIt.woff2') format('woff2'),
    url('Assets/fonts/MyriadPro-CondIt.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'MyriadPro-Semibold';
  src: url('Assets/fonts/MyriadPro-Semibold.woff2') format('woff2'),
    url('Assets/fonts/MyriadPro-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadPro';
  src: url('Assets/fonts/MyriadPro-Regular.woff2') format('woff2'),
    url('Assets/fonts/MyriadPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadPro-Bold';
  src: url('Assets/fonts/MyriadPro-Bold.woff2') format('woff2'),
    url('Assets/fonts/MyriadPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadPro-BoldCondIt';
  src: url('Assets/fonts/MyriadPro-BoldCondIt.woff2') format('woff2'),
    url('Assets/fonts/MyriadPro-BoldCondIt.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'MyriadPro-SemiboldIt';
  src: url('Assets/fonts/MyriadPro-SemiboldIt.woff2') format('woff2'),
    url('Assets/fonts/MyriadPro-SemiboldIt.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'MyriadPro-BoldCond';
  src: url('Assets/fonts/MyriadPro-BoldCond.woff2') format('woff2'),
    url('Assets/fonts/MyriadPro-BoldCond.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadPro-Cond';
  src: url('Assets/fonts/MyriadPro-Cond.woff2') format('woff2'),
    url('Assets/fonts/MyriadPro-Cond.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root {
  --white: #ffffff;
  --black: #000000;
  --fern-green: #467a3c;
  --greyish: #b0b0b0;
  --warm-grey: #858585;
  --greyish-brown: #575756;
  --scarlet: #d0021b;
  --white-99: rgba(216, 216, 216, 0.99);
  --black-two: #222221;
  --true-green: #008200;
  --pine: #205527;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: MyriadPro, Verdana, Tahoma, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-select {
  width: 100%;
}

.ant-select-dropdown> :first-child .ant-select-dropdown-menu {
  max-height: 160px;
}

.ant-layout-content {
  padding: 24px;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner img {
  display: none;
}

.ant-layout.ant-layout-has-sider>.ant-layout-content {
  overflow-x: auto;
}

table {
  table-layout: fixed;
}

.ant-table-tbody>tr>td {
  word-wrap: break-word;
  word-break: break-all;
}

.ant-menu-submenu>.ant-menu {
  padding: 5px 0;
}

.ant-modal-confirm .ant-modal-body {
  padding: 30px;
}

.ant-modal-content {
  border-radius: unset;
}

.ant-btn {
  font-size: 12px;
  min-width: 100px;
}

.ant-btn.ant-btn-primary,
.ant-btn.ant-btn-link {
  font-weight: 600;
}

.ant-btn:focus,
.ant-btn:hover {
  color: #008200;
  border-color: #205527;
  background: #fff;
}

.ant-btn.ant-btn-danger {
  background-color: #d0021b;
  font-size: 12px;
  line-height: 2;
  color: #fff;
  height: 32px;
  font-weight: 600;
}

.ant-menu-item-selected>a {
  color: white;
}

.ant-checkbox>.ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.ant-checkbox>.ant-checkbox-inner::after {
  top: 45%;
  left: 30%;
}

.ant-layout-sider-trigger {
  background: #205527;
}

.ant-picker-ranges .ant-picker-preset>.ant-tag-blue {
  background: #ffffff !important;
}